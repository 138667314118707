<template>
  <v-app>
    <v-main :style="styleMain">
      <!-- Questys full logo-->
      <v-img
        :src="logoSource"
        max-height="250"
        max-width="250"
        class="mt-5 ml-5"
      ></v-img>

      <!-- Login Dialog -->
      <v-card width="400" class="mx-auto mt-16" :loading="isLoading">
        <!-- Login Title -->
        <v-card-title>
          <span :class="titleTextClass">{{ title }}</span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <!-- Login Form -->
          <v-form v-model="isValidFormInput">
            <v-text-field
              v-if="isPublicAccess"
              v-model="userRepository"
              :label="labelRepository"
              :disabled="isPublicAccess"
            >
            </v-text-field>
            <v-select
              v-else
              v-model="userRepository"
              :label="labelRepository"
              :prepend-icon="iconDatabase"
              :items="repositories"
              :rules="rulesRepository"
            >
              <template v-slot:append>
                <!-- Button list Repositories -->
                <base-tooltip-button
                  v-show="!isPublicAccess"
                  :fab="true"
                  :small="true"
                  :disabled="disabledListRepositories"
                  @click="listRepositories"
                >
                  <template v-slot:icon-name>
                    {{ iconRefresh }}
                  </template>
                  <template v-slot:toolTip>
                    Refresh Repositories
                  </template>
                </base-tooltip-button>
              </template>
            </v-select>

            <!-- Username -->
            <v-text-field
              label="Username"
              v-model="userName"
              v-on:keyup.enter="!disabledLogin && onLogin()"
              :disabled="isPublicAccess"
              :rules="rulesUsername"
              :prepend-icon="iconAccount"
            />

            <!-- Password -->
            <v-text-field
              v-model="userPassword"
              :disabled="isPublicAccess"
              :type="passwordTextFiledType"
              :rules="rulesPassword"
              :prepend-icon="iconLock"
              v-on:keyup.enter="!disabledLogin && onLogin()"
              label="Password"
              @keypress="checkCapsLock"
            >
              <template v-slot:append>
                <!-- Button Show Password -->
                <base-tooltip-button
                  v-if="!isPublicAccess"
                  :fab="true"
                  :small="true"
                  :disabled="disabledShowPassword"
                  @click="showPassword = !showPassword"
                >
                  <template v-slot:icon-name>
                    {{ iconPasswordVisibility }}
                  </template>
                  <template v-slot:toolTip>
                    {{ toolTipShowPassword }}
                  </template>
                </base-tooltip-button>

                <base-tooltip-icon
                  v-if="capsLock"
                  top
                  :icon-name="iconHint"
                  :icon-color-class="iconColorClass"
                >
                  Caps lock is on
                </base-tooltip-icon>
              </template>
            </v-text-field>
          </v-form>

          <base-alert v-model="hasError" :dismissible="false">{{
            currentError
          }}</base-alert>
        </v-card-text>

        <!-- command buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Button Cancel -->
          <base-tooltip-button v-show="visibleCancelLogin" @click="onCancel"
            >Cancel
            <template v-slot:toolTip>
              {{ toolTipCancel }}
            </template>
          </base-tooltip-button>
          <!-- Button Login -->
          <base-tooltip-button @click="onLogin" :disabled="disabledLogin"
            >{{ labelLogin }}
            <template v-slot:toolTip>
              {{ toolTipLogin }}
            </template>
          </base-tooltip-button>
        </v-card-actions>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
// design
import {
  Color,
  colorTheme,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { colorMD } from "@/design/colors/mdColors";
import { Text, displayTypes, fontEmphasis } from "@/design/text/Text";
import {
  iconShowPassword,
  iconHidePassword,
  iconAccount,
  iconLock,
  iconDatabase,
  iconRefresh,
  iconCaps
} from "@/design/icon/iconConst";

// assets
import backgroundUrl from "@/assets/icons/questys-bg.svg";

// model
import { requiredFiled } from "@/model/common/formats/validationFormats";

// services
import {
  getStoredBackupUser,
  getStoredUser
} from "@/services/user/userService";
import { getStoredAppModuleName } from "@/services/solution/solutionService";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user");

// mixins
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { appModuleNavigatable } from "@/mixins/shared/navigatable/appModuleNavigatable";
import { userMixin } from "@/mixins/shared/user/userMixin";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

export default {
  name: "Login",
  mixins: [
    baseComponentMixin,
    appModuleNavigatable,
    userMixin,
    publicAccessMixin
  ],
  components: {
    // BaseButton: () => import("@/components/shared/base/BaseButton"),
    BaseAlert: () => import("@/components/shared/base/BaseAlert"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    BaseTooltipIcon: () => import("@/components/shared/base/BaseTooltipIcon")
  },
  data() {
    return {
      showPassword: false,
      isValidFormInput: false,
      backgroundUrl: backgroundUrl,
      logoSource: require("@/assets/icons/questys-logo-full.svg"),

      userRepository: undefined,
      userPassword: undefined,
      userName: undefined,

      // moduleIcons
      iconAccount: iconAccount,
      iconLock: iconLock,
      iconDatabase: iconDatabase,
      iconRefresh: iconRefresh,

      // field labels
      labelLogin: "Login",

      titleTextClass: new Text(
        new Color(colorMD.indigo, variantType.lighten, variantNumber.n1),
        displayTypes.title,
        fontEmphasis.bold
      ).getClassText(),

      backgroundColor: new Color(
        colorMD.teal,
        variantType.darken,
        variantNumber.n1
      ),

      // validation rules
      rulesRepository: [value => !!value || requiredFiled("Repository")],
      rulesUsername: [value => !!value || requiredFiled("Username")],
      rulesPassword: [value => !!value || requiredFiled("Password")],

      capsLock: false,
      iconHint: iconCaps,
      iconColorClass: colorTheme.warning
    };
  },
  computed: {
    passwordTextFiledType() {
      return this.showPassword ? "text" : "password";
    },
    iconPasswordVisibility() {
      return this.showPassword ? iconShowPassword : iconHidePassword;
    },
    styleMain() {
      // backgroundColor: this.backgroundColor.getClassColor(),
      return {
        backgroundImage: `url(${this.backgroundUrl}), linear-gradient(270deg, #00798f 0%, #1c84ab 28%, #482b80 100%)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
      };
    },
    repositoryCount() {
      return this.repositories ? this.repositories.length : 0;
    },
    title() {
      return this.isChangingRepository ? "Change Repository" : this.labelLogin;
    },
    isChangingRepository() {
      return !!getStoredBackupUser();
    },
    visibleAllPublicAccessModules() {
      return this.isPublicAccess
        ? this.publicAccess.modules.visibleFolder &&
            this.publicAccess.modules.visibleCategory &&
            this.publicAccess.modules.visibleMeetings
        : true;
    },
    visibleCancelLogin() {
      return this.isChangingRepository;
    },
    disabledLogin() {
      return this.isLoading || !this.isValidFormInput;
    },
    disabledListRepositories() {
      return this.isLoading;
    },
    disabledShowPassword() {
      return this.isLoading;
    },
    toolTipShowPassword() {
      return this.showPassword ? "Mask Password" : "Show Password";
    },
    labelRepository() {
      return this.isChangingRepository ? "Choose Repository" : "Repository";
    },
    toolTipCancel() {
      const storedUser = this.isPublicAccess ? undefined : getStoredUser();
      const repository = storedUser?.repository ?? `current repository`;
      return `Cancel Changes and Remain Logged In to the ${repository} Repository`;
    },
    toolTipLogin() {
      return `Login to ${this.userRepository} Repository`;
    }
  },
  methods: {
    ...mapActions({
      login: "login",
      setRepositories: "setRepositories",
      setCategoryTypes: "setCategoryTypes",
      cancelChangeRepository: "cancelChangeRepository"
    }),

    async onLogin() {
      try {
        this.clearError();
        this.isLoading = true;

        /**
         * set login payload
         * @type {{password:string, userName: string, repository: string}}
         */
        const payload = {
          userName: this.userName,
          password: this.userPassword,
          repository: this.userRepository
        };

        console.log(`Login ${payload.userName} to: ${payload.repository}`);

        await this.login(payload);

        // Do not redirect To if is Changing Repository or Public Access Modules disabled,
        // since route arguments may not be valid within new selected repo
        const redirectTo =
          this.isChangingRepository || !this.visibleAllPublicAccessModules
            ? undefined
            : this.$route.query.redirectTo;

        if (redirectTo) {
          this.$router
            .push(redirectTo)
            .then(response => {
              console.log("After Login Redirected To:", response);
            })
            .catch(e => {
              this.handleError(e);
              return Promise.reject(e);
            });
        } else {
          const appRoute = this.isPublicAccess
            ? this.buildAppModuleRoute(this.moduleNames.Search)
            : this.buildAppModuleRoute(this.moduleNames.Folder);
          this.$router
            .push(appRoute)
            .then(response => {
              console.log("After Login Routed To:", response);
            })
            .catch(e => {
              this.handleError(e);
              return Promise.reject(e);
            });
        }
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },
    onCancel() {
      try {
        this.cancelChangeRepository();

        const appModuleName = getStoredAppModuleName();
        const appRoute = appModuleName
          ? this.buildAppModuleRoute(appModuleName)
          : this.buildAppModuleRoute(this.moduleNames.Category);

        this.$router.push(appRoute).catch(err => {
          return Promise.reject(err);
        });
      } catch (e) {
        this.handleError(e);
      }
    },
    async listRepositories() {
      try {
        this.clearError();
        this.isLoading = true;

        await this.setRepositories();

        const storedUser = this.isPublicAccess ? undefined : getStoredUser();

        this.userName = this.isPublicAccess
          ? this.publicAccess?.searcher?.name ?? ""
          : storedUser?.name ?? "";

        // Auto-select Repository
        const repository = this.isPublicAccess
          ? this.publicAccess?.searcher?.repository ?? ""
          : storedUser?.repository ?? "";
        const selectRepository = this.repositories.find(
          el => el === repository
        );

        this.userRepository = this.isPublicAccess
          ? repository
          : selectRepository
          ? selectRepository
          : this.repositoryCount > 0
          ? this.repositories[0]
          : undefined;

        console.log(
          `listRepositories() this.userRepository:`,
          this.userRepository
        );
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Event to check CapsLock key press
     * @param event
     */
    checkCapsLock(event) {
      this.capsLock = event.getModifierState("CapsLock") && !event.shiftKey;
    }
  },
  async created() {
    try {
      await this.listRepositories();

      /**
       * Handle Public Access
       */
      if (this.isPublicAccess) {
        this.userPassword = this.publicAccess?.searcher?.code ?? "";

        /**
         * Auto-login Public Access user to Public Access repository
         */
        await this.onLogin();
      }
    } catch (e) {
      this.handleError(e);
    }
  }
};
</script>
