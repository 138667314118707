/**
 * Format required Filed
 * @param {String|string} fieldLabel
 * @returns {String}
 */
const requiredFiled = fieldLabel => `${fieldLabel} is required.`;

/**
 * Format required Input Filed Label
 * @param {String|string} fieldLabel
 * @returns {string}
 */
const requiredInputFiledLabel = fieldLabel => `* ${fieldLabel}`;

export { requiredFiled, requiredInputFiledLabel };
